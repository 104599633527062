import React, { useMemo } from "react"
import { Search } from "@/hooks/useSearch"
import { STRING_SEPARATOR } from "../constants"
import { TrackingContextProvider } from "../TrackingContext.react"

const MAX_NUMERIC_TRAITS_TO_LOG = 10
const MAX_STRING_TRAITS_TO_LOG = 20

type SearchContext = Omit<
  Search,
  | "categories"
  | "chains"
  | "collections"
  | "eventTypes"
  | "numericTraits"
  | "stringTraits"
  | "toggles"
  | "safelistRequestStatuses"
> & {
  categories: string | undefined
  chains: string | undefined
  collections: string | undefined
  eventTypes: string | undefined
  numericTraits: { name: string; ranges: string }[] | undefined
  stringTraits: { name: string; values: string }[] | undefined
  toggles: string | undefined
  safelistRequestStatuses: string | undefined
}

export type SearchTrackingContext = {
  Search: SearchContext
}

export type SearchTrackingContextProviderProps = {
  searchState: Search
  children: React.ReactNode
}

export const SearchTrackingContextProvider = ({
  searchState,
  children,
}: SearchTrackingContextProviderProps) => {
  const properties: SearchContext = useMemo(
    () => ({
      ...searchState,
      categories: searchState.categories?.join(STRING_SEPARATOR),
      chains: searchState.chains?.join(STRING_SEPARATOR),
      collections: searchState.collections?.join(STRING_SEPARATOR),
      eventTypes: searchState.eventTypes?.join(STRING_SEPARATOR),
      toggles: searchState.toggles?.join(STRING_SEPARATOR),
      safelistRequestStatuses:
        searchState.safelistRequestStatuses?.join(STRING_SEPARATOR),
      numericTraits: searchState.numericTraits
        ?.map(trait => ({
          name: trait.name,
          ranges: trait.ranges
            .map(range => `${range.min}-${range.max}`)
            .join(STRING_SEPARATOR),
        }))
        .slice(0, MAX_NUMERIC_TRAITS_TO_LOG),
      stringTraits: searchState.stringTraits
        ?.map(trait => ({
          name: trait.name,
          values: trait.values.join(STRING_SEPARATOR),
        }))
        .slice(0, MAX_STRING_TRAITS_TO_LOG),
    }),
    [searchState],
  )

  return (
    <TrackingContextProvider name="Search" properties={properties}>
      {children}
    </TrackingContextProvider>
  )
}
