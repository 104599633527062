import { useEffect, useMemo } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { useConnectedAddress } from "@/containers/WalletProvider/WalletProvider.react"
import { useTranslate } from "@/hooks/useTranslate"
import { useGetEligibleItemsForSweep_items$key } from "@/lib/graphql/__generated__/useGetEligibleItemsForSweep_items.graphql"
import { addressesEqual } from "@/lib/helpers/address"
import { MAX_BACKUP_ORDERS_TO_FILL, MAX_SWEEP_ITEMS } from "../constants"
import {
  useSweepEligibleItems,
  useSweepFormDesiredNumberOfItems,
  useSweepFormIsSweepModeToggled,
  useSweepFormMaxPricePerItem,
  useSweepItemIneligibilityReasonMap,
} from "../SweepContextProvider.react"

export const useGetEligibleItemsForSweep = (
  itemsDataKey: useGetEligibleItemsForSweep_items$key | null,
) => {
  const { desiredNumberOfItems } = useSweepFormDesiredNumberOfItems()
  const { maxPricePerItem } = useSweepFormMaxPricePerItem()
  const connectedAddress = useConnectedAddress()
  const t = useTranslate("bulkPurchase")

  const allItems = useFragment(
    graphql`
      fragment useGetEligibleItemsForSweep_items on ItemType
      @relay(plural: true) {
        __typename
        relayId
        chain {
          identifier
        }
        orderData {
          bestAskV2 {
            relayId
            orderType
            maker {
              address
            }
            perUnitPriceType {
              usd
              unit
              symbol
            }
            payment {
              relayId
              symbol
              usdPrice
            }
          }
        }
        ...SweepContextProvider_items
      }
    `,
    itemsDataKey,
  )

  const allItemKeys = (
    allItems?.flatMap(item =>
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      item.orderData?.bestAskV2
        ? [item.relayId, item.orderData.bestAskV2.relayId]
        : [],
    ) ?? []
  ).join()

  const { eligibleItems, ineligibleItemsMap } = useMemo(() => {
    const ineligibleItemsMap = new Map<string, string>()

    const filterInherentlyIneligibleOrders = (
      item: NonNullable<typeof allItems>[number],
    ) => {
      const bestAskV2 = item.orderData.bestAskV2

      const isBundle = item.__typename === "AssetBundleType"
      const isOwnOrder = addressesEqual(
        bestAskV2?.maker.address,
        connectedAddress,
      )
      const isRestricted = bestAskV2?.orderType === "ENGLISH"

      if (!bestAskV2) {
        ineligibleItemsMap.set(
          item.relayId,
          t("sweep.ineligible.notForSale", "This item is not listed for sale."),
        )

        return false
      } else if (isBundle) {
        ineligibleItemsMap.set(
          item.relayId,
          t("sweep.ineligible.bundle", "Sweeping bundles is not supported."),
        )

        return false
      } else if (isOwnOrder) {
        ineligibleItemsMap.set(
          item.relayId,
          t("sweep.ineligible.ownItem", "You own this item."),
        )

        return false
      } else if (isRestricted) {
        ineligibleItemsMap.set(
          item.relayId,
          t(
            "sweep.ineligible.english",
            "Sweeping English Auction listings is not supported.",
          ),
        )

        return false
      }

      return true
    }

    const filterInterrelatedIncompatibleOrders = (
      item: NonNullable<typeof allItems>[number],
      index: number,
      items: NonNullable<typeof allItems>,
    ) => {
      const firstItemChain = items[0].chain
      const firstItemPaymentAsset = items[0].orderData.bestAskV2?.payment
      const bestAskV2 = item.orderData.bestAskV2

      const isSameChain = item.chain.identifier === firstItemChain.identifier
      const isSamePaymentAsset =
        bestAskV2?.payment.relayId === firstItemPaymentAsset?.relayId

      if (!bestAskV2) {
        return false
      }

      if (!isSameChain) {
        ineligibleItemsMap.set(
          item.relayId,
          t(
            "sweep.ineligible.differentChain",
            "Sweeping items from different chains is not supported.",
          ),
        )

        return false
      }

      if (!isSamePaymentAsset) {
        ineligibleItemsMap.set(
          item.relayId,
          t(
            "sweep.ineligible.differentPaymentAsset",
            "Sweeps only supports single currency listings.",
          ),
        )

        return false
      }

      if (maxPricePerItem && firstItemPaymentAsset) {
        const { perUnitPriceType } = bestAskV2

        if (
          perUnitPriceType.symbol === firstItemPaymentAsset.symbol &&
          maxPricePerItem.gte(perUnitPriceType.unit)
        ) {
          return true
        }

        if (
          maxPricePerItem
            .multipliedBy(firstItemPaymentAsset.usdPrice)
            .gte(perUnitPriceType.usd)
        ) {
          return true
        }

        if (
          maxPricePerItem.gt(0) &&
          index >= (desiredNumberOfItems || 0) + MAX_BACKUP_ORDERS_TO_FILL
        ) {
          ineligibleItemsMap.set(
            item.relayId,
            t(
              "sweep.ineligible.tooManySubstituted",
              "The number of substituted items is capped at {{maxOrders}}.",
              { maxOrders: MAX_BACKUP_ORDERS_TO_FILL },
              { forceString: true },
            ),
          )

          return false
        }

        ineligibleItemsMap.set(
          item.relayId,
          t(
            "sweep.ineligible.tooExpensive",
            "This listing exceeds the maximum price per item.",
          ),
        )
        return false
      }

      return true
    }

    const filterMaxItems = (
      item: NonNullable<typeof allItems>[number],
      index: number,
    ) => {
      if (!maxPricePerItem && index >= MAX_SWEEP_ITEMS) {
        ineligibleItemsMap.set(
          item.relayId,
          t(
            "sweep.ineligible.tooMany",
            "The max number of items to sweep is {{maxItems}}.",
            { maxItems: MAX_SWEEP_ITEMS },
            { forceString: true },
          ),
        )

        return false
      }

      if (
        maxPricePerItem?.gt(0) &&
        index >= (desiredNumberOfItems || 0) + MAX_BACKUP_ORDERS_TO_FILL
      ) {
        ineligibleItemsMap.set(
          item.relayId,
          t(
            "sweep.ineligible.tooManySubstituted",
            "The number of substituted items is capped at {{maxOrders}}.",
            { maxOrders: MAX_BACKUP_ORDERS_TO_FILL },
            { forceString: true },
          ),
        )

        return false
      }

      return true
    }

    return {
      eligibleItems: allItems
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        ?.filter(i => i?.orderData)
        ?.filter(filterInherentlyIneligibleOrders)
        .filter(filterInterrelatedIncompatibleOrders)
        .filter(filterMaxItems),
      ineligibleItemsMap,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allItemKeys, desiredNumberOfItems, maxPricePerItem, t, connectedAddress])

  return { eligibleItems, ineligibleItemsMap }
}

export const useSetEligibleItemsForSweep = (
  itemsDataKey: useGetEligibleItemsForSweep_items$key | null,
) => {
  const { setEligibleItemsKey } = useSweepEligibleItems()
  const { setItemIneligibilityReasonMap } = useSweepItemIneligibilityReasonMap()

  const isSweepModeToggled = useSweepFormIsSweepModeToggled()

  const { eligibleItems, ineligibleItemsMap } =
    useGetEligibleItemsForSweep(itemsDataKey)

  const eligibleItemsKeys = (
    eligibleItems?.flatMap(item =>
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      item.orderData?.bestAskV2
        ? [item.relayId, item.orderData.bestAskV2.relayId]
        : [],
    ) ?? []
  ).join()

  useEffect(() => {
    if (isSweepModeToggled) {
      if (eligibleItems) {
        setEligibleItemsKey(eligibleItems)
      }
      if (ineligibleItemsMap.size) {
        setItemIneligibilityReasonMap(ineligibleItemsMap)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isSweepModeToggled,
    eligibleItemsKeys,
    ineligibleItemsMap,
    setEligibleItemsKey,
    setItemIneligibilityReasonMap,
  ])
}
