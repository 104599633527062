import { graphql } from "relay-runtime"
import { readItemBestAskUsdPrice_item$data } from "@/lib/graphql/__generated__/readItemBestAskUsdPrice_item.graphql"
import { inlineFragmentize } from "@/lib/graphql/inline"
import { BigNumber, bn } from "@/lib/helpers/numberUtils"

export const readItemBestAskUsdPrice = inlineFragmentize<
  readItemBestAskUsdPrice_item$data,
  BigNumber | undefined
>(
  graphql`
    fragment readItemBestAskUsdPrice_item on ItemType @inline {
      orderData {
        bestAskV2 {
          perUnitPriceType {
            usd
          }
          __typename
        }
      }
    }
  `,

  data =>
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    data.orderData?.bestAskV2?.perUnitPriceType.usd
      ? bn(data.orderData.bestAskV2.perUnitPriceType.usd)
      : undefined,
)
