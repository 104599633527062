import React, { useEffect, useRef, useState } from "react"
import {
  Icon,
  useIsLessThanLg,
  UnstyledButton,
  Text,
  classNames,
  Flex,
} from "@opensea/ui-kit"
import styled, { css } from "styled-components"
import { MARKDOWN_LINE_CLASS } from "@/design-system/Markdown"
import { useLayoutEffect } from "@/hooks/useIsomorphicLayoutEffect"
import { useSize } from "@/hooks/useSize"
import { useTranslate } from "@/hooks/useTranslate"

export type TextOverflowProps = {
  children: React.ReactNode
  lines: number
  onSeeMore?: (seeMore: boolean) => void
  inlineSeeMore?: boolean
  className?: string
  seeMoreClassName?: string
  showExpandIcon?: boolean
  maxHeight?: number
}

// TODO: consolidate this with the component inside AccountOrCollectionInfo
export const TextOverflow = ({
  children,
  lines = 1,
  onSeeMore,
  inlineSeeMore,
  className,
  seeMoreClassName,
  showExpandIcon = true,
  maxHeight,
}: TextOverflowProps) => {
  const t = useTranslate("phoenix")
  const isSmallScreen = useIsLessThanLg()
  const [seeMore, setSeeMore] = useState(false)
  const [overflowed, setOverflowed] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)

  const showSeeMoreButton = overflowed || seeMore

  const onSeeMoreRef = useRef<TextOverflowProps["onSeeMore"]>()
  onSeeMoreRef.current = onSeeMore
  useEffect(() => {
    onSeeMoreRef.current?.(seeMore)
  }, [seeMore])
  const [_width, height] = useSize(containerRef)
  const [maxLineLength, setMaxLineLength] = useState(0)
  // This is sorta terrible, and causes reflow, but it seems to be the only way
  // to get "see more" to show to the right of a short line, when there are
  // longer lines in the body that are truncated
  useLayoutEffect(() => {
    if (!containerRef.current) {
      return
    }
    const lineElements = containerRef.current.querySelectorAll(
      `.${MARKDOWN_LINE_CLASS}`,
    )
    const containerBox = containerRef.current.getBoundingClientRect()
    const maxLineWidth = Array.from(lineElements).reduce((max, elem) => {
      if (elem.getBoundingClientRect().top < containerBox.bottom) {
        return Math.max(max, elem.getBoundingClientRect().width)
      }
      return max
    }, 0)
    if (maxLineWidth) {
      setMaxLineLength(maxLineWidth)
    }
  }, [overflowed, seeMore, height, maxLineLength])

  useEffect(() => {
    // Set max line length to 0 so we re-compute the max width of the container
    setMaxLineLength(0)
  }, [children])

  return (
    <Flex
      className={classNames(
        "relative",
        inlineSeeMore && !seeMore ? "flex-row" : "flex-col",
        className,
      )}
    >
      <Flex>
        <SetTextOverflow lines={lines} setOverflowed={setOverflowed}>
          {children}
        </SetTextOverflow>
        <Flex>
          <OverflowContainer
            $lines={seeMore ? undefined : lines}
            $maxHeight={maxHeight}
            $maxWidth={seeMore ? undefined : maxLineLength}
            ref={containerRef}
          >
            {children}
          </OverflowContainer>
        </Flex>
      </Flex>
      {showSeeMoreButton && (
        <UnstyledButton
          className={classNames("items-end", {
            "ml-1": !seeMore && inlineSeeMore,
            "mt-1": seeMore && inlineSeeMore,
          })}
          onClick={() => {
            if (seeMore && containerRef.current) {
              containerRef.current.scrollTop = 0
            }
            setSeeMore(!seeMore)
          }}
        >
          <Flex className="items-center">
            <Text.Body
              asChild
              className={classNames(
                "whitespace-nowrap hover:opacity-80 active:opacity-70",
                seeMoreClassName,
              )}
              responsive
            >
              <span>
                {seeMore
                  ? t("info.seeLess", "See less")
                  : t("info.seeMore", "See more")}
              </span>
            </Text.Body>
            {showExpandIcon && (
              <ExpandIcon
                size={isSmallScreen ? 16 : 20}
                value={seeMore ? "expand_less" : "expand_more"}
              />
            )}
          </Flex>
        </UnstyledButton>
      )}
    </Flex>
  )
}

// This component is used to determine if the text is being truncated or not.
const SetTextOverflow = ({
  children,
  setOverflowed,
  lines,
}: {
  children: React.ReactNode
  setOverflowed: (overflowed: boolean) => void
  lines: number
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const duplicateRef = useRef<HTMLDivElement>(null)
  const [containerWidth] = useSize(containerRef)

  useLayoutEffect(() => {
    if (containerRef.current && duplicateRef.current) {
      setOverflowed(
        duplicateRef.current.clientHeight > containerRef.current.clientHeight,
      )
    }
  }, [containerWidth, setOverflowed])

  return (
    <Flex className="invisible absolute inset-0">
      <OverflowContainer
        $lines={lines}
        className="invisible absolute left-0 top-0"
        ref={containerRef}
      >
        {children}
      </OverflowContainer>
      {/* We add a duplicated element that isn't being trunctated and isn't visible. So as long as their size match, that means the text isn't being truncated yet. */}
      <Flex
        aria-hidden="true"
        className="invisible absolute left-0 top-0"
        ref={duplicateRef}
      >
        {children}
      </Flex>
    </Flex>
  )
}

const OverflowContainer = styled(Flex)<{
  $lines?: number
  $maxHeight?: number
  $maxWidth?: number
}>`
  ${({ $maxWidth }) =>
    $maxWidth &&
    css`
      max-width: ${$maxWidth}px;
    `};
  ${({ $maxHeight }) =>
    $maxHeight &&
    css`
      max-height: ${$maxHeight}px;
      overflow: auto;
    `};
  ${({ $lines }) =>
    $lines &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: ${$lines};
      -webkit-box-orient: vertical;
      white-space: normal;
    `}
`

const ExpandIcon = styled(Icon).attrs({ variant: "outlined" })``
