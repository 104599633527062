import React, { useEffect, useMemo, useState } from "react"
import { Icon, SpaceBetween, Text, classNames } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import { useDebouncedCallback } from "use-debounce"
import {
  useSearchContextLastUpdatedAt,
  useSearchContextTotalCount,
} from "@/components/search/utils/SearchContextProvider.react"
import { IconButton } from "@/design-system/IconButton"
import { useIsCollectionEligibleForLiveUpdates } from "@/features/live-updates/hooks/useIsLiveUpdatesEnabledForCollection"
import { useTranslate } from "@/hooks/useTranslate"
import { AssetSearchRefreshBar_collection$key } from "@/lib/graphql/__generated__/AssetSearchRefreshBar_collection.graphql"
import { dateFromISO8601 } from "@/lib/helpers/datetime"

type Props = {
  collection: AssetSearchRefreshBar_collection$key | null
  onRefresh: () => void
}

export const AssetSearchRefreshBar = ({
  collection: collectionDataKey,
  onRefresh,
}: Props) => {
  const t = useTranslate("components")
  const [lastUpdatedAt] = useSearchContextLastUpdatedAt()
  const [totalCount] = useSearchContextTotalCount()

  const collection = useFragment(
    graphql`
      fragment AssetSearchRefreshBar_collection on CollectionType {
        ...useIsLiveUpdatesEnabledForCollection_collection
      }
    `,
    collectionDataKey,
  )

  const isUnsupportedLiveUpdatesCollection =
    !useIsCollectionEligibleForLiveUpdates(collection)

  const lastUpdatedAtDate = useMemo(
    () => (lastUpdatedAt ? dateFromISO8601(lastUpdatedAt) : undefined),
    [lastUpdatedAt],
  )

  // Only show updated time once the value settles. The sudden value changes are
  // caused by the value being updated by two GraphQL requests.
  const [_, setIsUpdatedTimeVisible] = useState(false)
  const showUpdatedTime = useDebouncedCallback(() => {
    setIsUpdatedTimeVisible(true)
  }, 500)

  useEffect(() => {
    if (lastUpdatedAtDate) {
      showUpdatedTime()
    }
  }, [lastUpdatedAtDate, showUpdatedTime])

  return (
    <>
      {isUnsupportedLiveUpdatesCollection && (
        <IconButton
          className="mr-2"
          disabled={totalCount === undefined}
          onClick={onRefresh}
        >
          <Icon value="refresh" />
        </IconButton>
      )}
      {totalCount || totalCount === 0 ? (
        <SpaceBetween className="w-full">
          <Text
            asChild
            className={classNames(
              isUnsupportedLiveUpdatesCollection ? "pb-0" : "pb-2",
            )}
            color="inherit"
            weight="semibold"
          >
            <p>
              {t(
                "assetSearchRefreshBar.totalCount.localized",
                {
                  "0": "{{localizedCount}} items",
                  one: "{{localizedCount}} item",
                  other: "{{localizedCount}} items",
                },
                {
                  count: totalCount,
                  localizedCount: totalCount.toLocaleString(),
                },
              )}
            </p>
          </Text>
        </SpaceBetween>
      ) : (
        <Text
          asChild
          className={classNames(
            isUnsupportedLiveUpdatesCollection ? "pb-0" : "pb-2",
          )}
          color="secondary"
        >
          <p>{t("assetSearchRefreshBar.loading", "Loading items...")}</p>
        </Text>
      )}
    </>
  )
}
