import { useMemo, useRef } from "react"
import { graphql, useSubscription } from "react-relay"
import { GraphQLSubscriptionConfig } from "relay-runtime"
import { SlugProps } from "@/features/live-updates/components/CollectionPageSubscriptions/CollectionPageSubscriptions.react"
import { eventHistory } from "@/features/live-updates/components/eventHistory"
import { trackDisconnects } from "@/lib/analytics/events/liveUpdatesEvents"
import { useCollectionPageSaleEventSubscription } from "@/lib/graphql/__generated__/useCollectionPageSaleEventSubscription.graphql"

export const useCollectionPageSaleEvent = ({
  slug,
  onSubscribe,
}: SlugProps & { onSubscribe: () => void }) => {
  const disconnectRef = useRef(0)
  const config: GraphQLSubscriptionConfig<useCollectionPageSaleEventSubscription> =
    useMemo(
      () => ({
        onNext: payload => {
          eventHistory.pushSaleEvent(payload)
        },
        onCompleted: () => {
          trackDisconnects({ disconnects: disconnectRef.current })
          disconnectRef.current += 1
        },
        variables: { slug },
        subscription: graphql`
          subscription useCollectionPageSaleEventSubscription($slug: String!) {
            saleEvent(slug: $slug) {
              __typename
              ... on SaleControlEvent {
                kind
              }
              ... on SaleEvent {
                item {
                  relayId
                }
              }
            }
          }
        `,
        updater: (store, data) => {
          if (data.saleEvent.__typename === "SaleControlEvent") {
            if (data.saleEvent.kind === "Connected") {
              onSubscribe()
            }
            return
          }
          if (data.saleEvent.__typename !== "SaleEvent") {
            return
          }
          const relayId = data.saleEvent.item?.relayId
          if (!relayId) {
            return
          }
          const proxy = store.get(relayId)
          if (!proxy) {
            return
          }
          proxy.setValue(true, "saleOrListingCancellationOccurred")
        },
      }),
      [slug, onSubscribe],
    )

  useSubscription<useCollectionPageSaleEventSubscription>(config)
}
