import React from "react"
import dynamic from "next/dynamic"
import { Modal, ControlledModalProps } from "@/design-system/Modal"
import type { ReportModalContentProps } from "./ReportModalContent.react"

const ReportModalContent = dynamic<ReportModalContentProps>(() =>
  import(
    "@/features/report/components/ReportModal/ReportModalContent.react"
  ).then(mod => mod.ReportModalContent),
)

export type ReportModalProps = ReportModalContentProps &
  Required<Pick<ControlledModalProps, "isOpen">>

export const ReportModal = ({ isOpen, onClose, ...rest }: ReportModalProps) => {
  return (
    <Modal focusFirstFocusableElement={false} isOpen={isOpen} onClose={onClose}>
      <ReportModalContent {...rest} onClose={onClose} />
    </Modal>
  )
}
