import { useMemo } from "react"
import { graphql, useSubscription } from "react-relay"
import { GraphQLSubscriptionConfig } from "relay-runtime"
import { ContractAddressProps } from "@/features/live-updates/components/CollectionPageSubscriptions/CollectionPageSubscriptions.react"
import { eventHistory } from "@/features/live-updates/components/eventHistory"
import { useCollectionPagePendingTxnEventSubscription } from "@/lib/graphql/__generated__/useCollectionPagePendingTxnEventSubscription.graphql"

export const useCollectionPagePendingTxnEvent = ({
  contractAddress,
}: ContractAddressProps) => {
  const config: GraphQLSubscriptionConfig<useCollectionPagePendingTxnEventSubscription> =
    useMemo(
      () => ({
        onNext: payload => {
          eventHistory.pushPendingTxnEvent(payload)
        },
        variables: { contractAddress },
        subscription: graphql`
          subscription useCollectionPagePendingTxnEventSubscription(
            $contractAddress: AddressScalar!
          ) {
            mempoolEvent(contractAddress: $contractAddress) {
              __typename
              ... on MempoolEvent {
                asset {
                  relayId
                }
                priorityFeeGwei
                maxFeeGwei
                transactionHash
                blockExplorerLink
                status
                seenAt
              }
            }
          }
        `,
        updater: (store, data) => {
          for (const event of data.mempoolEvent) {
            if (event.__typename !== "MempoolEvent") {
              continue
            }

            const relayId = event.asset?.relayId
            if (!relayId) {
              continue
            }
            const proxy = store.get(relayId)
            if (!proxy) {
              continue
            }

            const {
              priorityFeeGwei,
              maxFeeGwei,
              transactionHash,
              blockExplorerLink,
              status,
              seenAt,
            } = event

            const record = proxy.getOrCreateLinkedRecord(
              "pendingTxn",
              "PendingTxnType",
            )
            record.setValue(priorityFeeGwei, "priorityFeeGwei")
            record.setValue(maxFeeGwei, "maxFeeGwei")
            record.setValue(seenAt, "seenAt")
            record.setValue(transactionHash, "transactionHash")
            record.setValue(blockExplorerLink, "blockExplorerLink")
            record.setValue(status, "status")
          }
        },
      }),
      [contractAddress],
    )

  useSubscription<useCollectionPagePendingTxnEventSubscription>(config)
}
