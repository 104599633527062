import React, { useMemo } from "react"
import { useAccountOrCollectionPageContext } from "@/components/layout/AccountOrCollectionPage/hooks/useAccountOrCollectionPageContext"
import { AssetCardVariant } from "@/components/search/assets/AssetSearchView"
import { TrackingContextProvider } from "../TrackingContext.react"
import {
  SearchTrackingContextProvider,
  SearchTrackingContextProviderProps,
} from "./SearchTrackingContext.react"

type ItemSearchContext = {
  displayVariant: AssetCardVariant | "unset"
}

export type ItemSearchTrackingContext = {
  ItemSearch: ItemSearchContext
}

type ItemSearchTrackingContextProviderProps = SearchTrackingContextProviderProps

export const ItemSearchTrackingContextProvider = ({
  searchState,
  children,
}: ItemSearchTrackingContextProviderProps) => {
  const { selectedCardVariant } = useAccountOrCollectionPageContext()

  const properties: ItemSearchContext = useMemo(
    () => ({
      displayVariant: selectedCardVariant ?? "unset",
    }),
    [selectedCardVariant],
  )

  return (
    <TrackingContextProvider name="ItemSearch" properties={properties}>
      <SearchTrackingContextProvider searchState={searchState}>
        {children}
      </SearchTrackingContextProvider>
    </TrackingContextProvider>
  )
}
