import { useMemo } from "react"
import { graphql, useSubscription } from "react-relay"
import { GraphQLSubscriptionConfig } from "relay-runtime"
import { SlugProps } from "@/features/live-updates/components/CollectionPageSubscriptions/CollectionPageSubscriptions.react"
import { eventHistory } from "@/features/live-updates/components/eventHistory"
import { updateCollectionStoreWithListingUpdatedEventData } from "@/features/live-updates/utils"
import { useCollectionPageListingUpdatedEventSubscription } from "@/lib/graphql/__generated__/useCollectionPageListingUpdatedEventSubscription.graphql"

export const useCollectionPageListingUpdatedEvent = ({ slug }: SlugProps) => {
  const config: GraphQLSubscriptionConfig<useCollectionPageListingUpdatedEventSubscription> =
    useMemo(
      () => ({
        onNext: payload => {
          eventHistory.pushListingUpdatedEvent(payload)
        },
        variables: { slug },
        subscription: graphql`
          subscription useCollectionPageListingUpdatedEventSubscription(
            $slug: String!
          ) {
            listingUpdatedEvent(slug: $slug) {
              itemRelayId
              newBestOrderRelayId
              newBestOrder {
                __typename
                insertedAt
                createdDate
                priceType {
                  eth
                  unit
                }
                orderType
                maker {
                  ...wallet_accountKey
                }
                # These come from useGetEligibleItemsForSweep_items
                # But that requests them directly nested inside item so we can't spread a fragment without a refactor
                perUnitPriceType {
                  usd
                  unit
                  symbol
                }
                payment {
                  relayId
                  symbol
                  usdPrice
                }
                ...QuickBuyButton_order
                ...ItemAddToCartButton_order
                ...AssetSearchListViewTableQuickBuy_order
                ...AddToCartAndQuickBuyButton_order
                ...useIsQuickBuyEnabled_order
                ...ItemCardPrice_data
              }
            }
          }
        `,

        updater: (store, data) => {
          updateCollectionStoreWithListingUpdatedEventData(store, data, slug)
        },
      }),
      [slug],
    )

  useSubscription<useCollectionPageListingUpdatedEventSubscription>(config)
}
