import React, { forwardRef } from "react"
import {
  useIsLessThanLg,
  useIsLessThanXl,
  Text,
  FlexColumn,
  Skeleton,
} from "@opensea/ui-kit"
import styled, { css } from "styled-components"
import { useSearchContextTotalCount } from "@/components/search/utils/SearchContextProvider.react"
import { Flex } from "@/design-system/Flex"
import { LivePulse } from "@/design-system/LivePulse"
import { LIVE_PULSE_VARIANT } from "@/design-system/LivePulse/LivePulse.react"
import { Tooltip } from "@/design-system/Tooltip"
import { useIsLiveUpdatesEnabledForCollection } from "@/features/live-updates/hooks/useIsLiveUpdatesEnabledForCollection"
import { useTranslate } from "@/hooks/useTranslate"
import { display } from "@/lib/helpers/numberUtils"

export const LiveDataIndicator = forwardRef<HTMLDivElement>(
  function LiveDataIndicator(_, ref) {
    const t = useTranslate("components")
    const isXL = !useIsLessThanXl()
    const lessThanL = useIsLessThanLg()
    const stack = !(lessThanL || isXL)
    const isPaused = !useIsLiveUpdatesEnabledForCollection(null)
    const [resultCount] = useSearchContextTotalCount()
    const tooltipContent = isPaused
      ? t(
          "liveDataIndicator.pausedTooltip",
          "Live data paused for custom filters and sorts",
        )
      : t("liveDataIndicator.liveTooltip", "Items update in real time")

    const itemCount =
      resultCount || resultCount === 0 ? (
        <ItemCount $stacked={stack}>
          {t(
            "liveDataIndicator.results",
            {
              "0": "{{displayCount}} results",
              one: "{{displayCount}} result",
              other: "{{displayCount}} results",
            },
            { count: resultCount, displayCount: display(resultCount) },
          )}
        </ItemCount>
      ) : (
        <ItemCountSkeleton $stacked={stack} />
      )

    const liveData = (
      <Tooltip content={tooltipContent}>
        <Row>
          <LivePulse
            variant={
              isPaused ? LIVE_PULSE_VARIANT.PAUSED : LIVE_PULSE_VARIANT.LIVE
            }
          />
          <Text.Body className="flex" size="medium" weight="semibold">
            {isPaused
              ? t("liveDataIndicator.paused", "Paused")
              : t("liveDataIndicator.live", "Live")}
          </Text.Body>
        </Row>
      </Tooltip>
    )

    return stack ? (
      <FlexColumn className="items-start" ref={ref}>
        {liveData}
        {itemCount}
      </FlexColumn>
    ) : (
      <Row ref={ref}>
        {liveData}
        {itemCount}
      </Row>
    )
  },
)

const Row = styled(Flex)`
  align-items: center;
  height: 28px;
`

const ItemCountSkeleton = styled(Skeleton.Line)<{ $stacked: boolean }>`
  width: 88px;
  ${props =>
    !props.$stacked &&
    css`
      margin-left: 24px;
    `}
`

const ItemCount = styled(Text.Body).attrs({
  size: "medium",
})<{ $stacked: boolean }>`
  ${props =>
    !props.$stacked &&
    css`
      margin-left: 24px;
    `}

  color: ${props => props.theme.colors.text.secondary};
`
