import { AssetCardVariant } from "@/components/search/assets/AssetSearchView"
import { CollectionAssetSearchState } from "./components/CollectionAssetSearch/CollectionAssetSearch.react"

type Props = {
  assetCardVariant?: AssetCardVariant
  searchState: CollectionAssetSearchState
}

export const shouldShowBestBid = ({ assetCardVariant, searchState }: Props) => {
  return assetCardVariant === "list-view" || searchState.sortBy === "BEST_BID"
}
