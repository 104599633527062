import React, { ReactNode } from "react"
import {
  Media,
  useIsLessThanLg,
  Container,
  classNames,
  SpaceBetween,
  Flex,
} from "@opensea/ui-kit"
import { AnimatePresence, motion } from "framer-motion"
import {
  RESULTS_FRAGMENT_ANCHOR_ID,
  FILTER_BAR_HEIGHT,
} from "@/components/layout/AccountOrCollectionPage/constants"
import { useAccountOrCollectionPageContext } from "@/components/layout/AccountOrCollectionPage/hooks/useAccountOrCollectionPageContext"
import { StickyBar, StickyBarProps } from "@/components/layout/StickyBar.react"
import { Block } from "@/design-system/Block"
import { $nav_height } from "@/styles/variables"

type Props = {
  assetCardVariantToggle?: ReactNode
  collectionOfferButton?: ReactNode
  filterToggle?: ReactNode
  sortDropdown?: ReactNode
  search?: ReactNode
  liveDataIndicator?: ReactNode
  topOffsetOverride?: number
  shadowVariant?: StickyBarProps["shadowVariant"]
  stats?: ReactNode
  numOffers?: ReactNode
  statusDropdown?: ReactNode
  typeDropdown?: ReactNode
  chainDropdown?: ReactNode
}

export function FilterBar({
  assetCardVariantToggle,
  shadowVariant,
  collectionOfferButton,
  filterToggle,
  sortDropdown,
  search,
  liveDataIndicator,
  topOffsetOverride,
  stats,
  numOffers,
  statusDropdown,
  typeDropdown,
  chainDropdown,
}: Props) {
  const { scrolledPastStats } = useAccountOrCollectionPageContext()
  const isMobileViewport = useIsLessThanLg()

  const filterBarContent = (
    <>
      <Media lessThan="lg">
        {mediaClassNames => (
          <Container
            className={classNames(
              "sticky flex w-full flex-col items-center",
              mediaClassNames,
            )}
            style={{ top: $nav_height }}
          >
            {(search || filterToggle || sortDropdown) && (
              <Flex className="mb-0 w-full gap-2 lg:mb-2">
                {filterToggle}
                {/* This seemingly unnecessary flex and div wrapper is needed to
            prevent a bug where the sortDropdown gets rendered as a BlockSkeleton: see https://opensea.slack.com/archives/C04BV4QV3NJ/p1679933435774489 */}
                {search && <Flex className="flex-1">{search}</Flex>}
                <Flex>{sortDropdown}</Flex>
                <Flex>{assetCardVariantToggle}</Flex>
                {numOffers}
              </Flex>
            )}
            {collectionOfferButton && (
              <Block className="w-full" marginTop="8px">
                {collectionOfferButton}
              </Block>
            )}
          </Container>
        )}
      </Media>
      <Media greaterThanOrEqual="lg">
        {mediaClassNames => (
          <Container
            className={classNames("flex h-full items-center", mediaClassNames)}
          >
            <Flex className="h-full w-full items-center">
              <Flex className="shrink-0 items-center">{filterToggle}</Flex>
              {liveDataIndicator && (
                <Block
                  flexShrink={{ _: 0 }}
                  marginLeft={filterToggle ? "16px" : undefined}
                  marginRight="12px"
                >
                  {liveDataIndicator}
                </Block>
              )}
              {typeDropdown && (
                <Block flexShrink={{ _: 0 }} marginLeft="16px" maxWidth={106}>
                  {typeDropdown}
                </Block>
              )}
              {statusDropdown && (
                <Block flexShrink={{ _: 0 }} marginLeft="16px" maxWidth={120}>
                  {statusDropdown}
                </Block>
              )}
              {chainDropdown && (
                <Block flexShrink={{ _: 0 }} marginLeft="16px" maxWidth={124}>
                  {chainDropdown}
                </Block>
              )}
              {search && (
                <Block className="w-full" marginLeft="12px">
                  {search}
                </Block>
              )}
              {sortDropdown && (
                <Block
                  flexShrink={{ _: 0 }}
                  marginLeft="auto"
                  paddingLeft="12px"
                  width="252px"
                >
                  {sortDropdown}
                </Block>
              )}
              {assetCardVariantToggle && (
                <Block marginLeft="12px">{assetCardVariantToggle}</Block>
              )}
              {collectionOfferButton && (
                <Block marginLeft="12px">{collectionOfferButton}</Block>
              )}
              {!!(numOffers || stats) && (
                <SpaceBetween className="w-full items-center">
                  {numOffers && <Block marginLeft="16px">{numOffers}</Block>}
                  {stats && (
                    <Flex className="ml-auto h-full items-center">
                      <AnimatePresence>
                        {scrolledPastStats && (
                          <motion.div
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            initial={{ opacity: 0 }}
                            style={{ width: "100%" }}
                          >
                            {stats}
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </Flex>
                  )}
                </SpaceBetween>
              )}
            </Flex>
          </Container>
        )}
      </Media>
    </>
  )

  return (
    <>
      <StickyBar
        // A bit hacky to margin offset the selected item card outline
        className="mb-1 lg:mb-0"
        elementId={RESULTS_FRAGMENT_ANCHOR_ID}
        height={FILTER_BAR_HEIGHT}
        shadowVariant={shadowVariant ?? "border"}
        topOffsetOverride={topOffsetOverride}
      >
        {filterBarContent}
      </StickyBar>
      {isMobileViewport && liveDataIndicator && (
        <Block className="w-full" marginBottom="24px" marginTop="16px">
          {liveDataIndicator}
        </Block>
      )}
    </>
  )
}
