import { useCollectionPageListingEventSubscription$data } from "@/lib/graphql/__generated__/useCollectionPageListingEventSubscription.graphql"
import { useCollectionPageListingUpdatedEventSubscription$data } from "@/lib/graphql/__generated__/useCollectionPageListingUpdatedEventSubscription.graphql"
import { useCollectionPagePendingTxnEventSubscription$data } from "@/lib/graphql/__generated__/useCollectionPagePendingTxnEventSubscription.graphql"
import { useCollectionPageSaleEventSubscription$data } from "@/lib/graphql/__generated__/useCollectionPageSaleEventSubscription.graphql"

declare global {
  interface Window {
    collectionPageEventHistory?: Map<string, SubscriptionEventHistory>
    enableEventHistory?: boolean
  }
}

type SubscriptionEventHistory = {
  errors: Error[]
  listingEvents: useCollectionPageListingEventSubscription$data[]
  listingUpdatedEvents: useCollectionPageListingUpdatedEventSubscription$data[]
  saleEvents: useCollectionPageSaleEventSubscription$data[]
  pendingTxnEvents: useCollectionPagePendingTxnEventSubscription$data[]
}

class CollectionPageEventHistory {
  private slug?: string
  private enabled = false

  public setSlug = (slug: string | undefined) => {
    this.slug = slug

    if (typeof window !== "undefined" && window.enableEventHistory) {
      this.enabled = true
      if (!window.collectionPageEventHistory) {
        window.collectionPageEventHistory = new Map()
      }
      if (slug && !window.collectionPageEventHistory.has(slug)) {
        window.collectionPageEventHistory.set(slug, {
          errors: [],
          listingEvents: [],
          listingUpdatedEvents: [],
          saleEvents: [],
          pendingTxnEvents: [],
        })
      }
    }
  }

  public pushError = (error: Error) => {
    if (this.enabled && this.slug) {
      window.collectionPageEventHistory?.get(this.slug)?.errors.push(error)
    }
  }

  public pushSaleEvent = (
    event: useCollectionPageSaleEventSubscription$data | null | undefined,
  ) => {
    if (this.enabled && this.slug && event) {
      window.collectionPageEventHistory?.get(this.slug)?.saleEvents.push(event)
    }
  }

  public pushListingEvent = (
    event: useCollectionPageListingEventSubscription$data | null | undefined,
  ) => {
    if (this.enabled && this.slug && event) {
      window.collectionPageEventHistory
        ?.get(this.slug)
        ?.listingEvents.push(event)
    }
  }

  public pushPendingTxnEvent = (
    event: useCollectionPagePendingTxnEventSubscription$data | null | undefined,
  ) => {
    if (this.enabled && this.slug && event) {
      window.collectionPageEventHistory
        ?.get(this.slug)
        ?.pendingTxnEvents.push(event)
    }
  }

  public pushListingUpdatedEvent = (
    event:
      | useCollectionPageListingUpdatedEventSubscription$data
      | null
      | undefined,
  ) => {
    if (this.enabled && this.slug && event) {
      window.collectionPageEventHistory
        ?.get(this.slug)
        ?.listingUpdatedEvents.push(event)
    }
  }
}

export const eventHistory = new CollectionPageEventHistory()
