import { getTrackingFn } from "@/lib/analytics"
import {
  getItemTrackingFn,
  getItemTrackingFnWithExtraParams,
} from "@/lib/analytics/events/itemEvents"
import type { ReportReason } from "@/lib/graphql/__generated__/ReportModalContentMutation.graphql"

export const trackSubmitAssetReport = getItemTrackingFnWithExtraParams<{
  additionalComments?: string
  originalCreatorUrl?: string
  reason?: ReportReason
}>("submit asset report")

export const trackSubmitAccountReport = getTrackingFn<{
  address: string
  additionalComments?: string
  originalCreatorUrl?: string
  reason?: ReportReason
}>("submit account report")

export const trackSubmitCollectionReport = getTrackingFn<{
  slug: string
  additionalComments?: string
  originalCreatorUrl?: string
  reason?: ReportReason
}>("submit collection report")

export const trackSubmitDropReport = getTrackingFn<{
  dropId: string
  collectionSlug: string
  additionalComments?: string
  originalCreatorUrl?: string
  reason?: ReportReason
}>("submit drop report")

export const trackOpenReportModal = getItemTrackingFn("open report modal")
