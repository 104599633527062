/**
 * @generated SignedSource<<fc39a46f7ff671807526f28b962584eb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ChainIdentifier = "AMOY" | "APE_CHAIN" | "APE_CURTIS" | "ARBITRUM" | "ARBITRUM_GOERLI" | "ARBITRUM_NOVA" | "ARBITRUM_SEPOLIA" | "AVALANCHE" | "AVALANCHE_FUJI" | "B3" | "B3_SEPOLIA" | "BAOBAB" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BERA_CHAIN" | "BLAST" | "BLAST_SEPOLIA" | "BSC" | "BSC_TESTNET" | "ETHEREUM" | "FLOW" | "FLOW_TESTNET" | "GOERLI" | "GUNZILLA" | "GUNZILLA_TESTNET" | "KLAYTN" | "MATIC" | "MONAD_TESTNET" | "MUMBAI" | "OPTIMISM" | "OPTIMISM_GOERLI" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "RONIN" | "SAIGON_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "SEPOLIA" | "SHAPE" | "SOLANA" | "SOLDEV" | "SONEIUM" | "SONEIUM_MINATO" | "UNICHAIN" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "%future added value";
export type OrderV2OrderType = "BASIC" | "CRITERIA" | "DUTCH" | "ENGLISH" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useGetEligibleItemsForSweep_items$data = ReadonlyArray<{
  readonly __typename: string;
  readonly chain: {
    readonly identifier: ChainIdentifier;
  };
  readonly orderData: {
    readonly bestAskV2: {
      readonly maker: {
        readonly address: string;
      };
      readonly orderType: OrderV2OrderType;
      readonly payment: {
        readonly relayId: string;
        readonly symbol: string;
        readonly usdPrice: string;
      };
      readonly perUnitPriceType: {
        readonly symbol: string;
        readonly unit: string;
        readonly usd: string;
      };
      readonly relayId: string;
    } | null;
  };
  readonly relayId: string;
  readonly " $fragmentSpreads": FragmentRefs<"SweepContextProvider_items">;
  readonly " $fragmentType": "useGetEligibleItemsForSweep_items";
}>;
export type useGetEligibleItemsForSweep_items$key = ReadonlyArray<{
  readonly " $data"?: useGetEligibleItemsForSweep_items$data;
  readonly " $fragmentSpreads": FragmentRefs<"useGetEligibleItemsForSweep_items">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "useGetEligibleItemsForSweep_items",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ChainType",
      "kind": "LinkedField",
      "name": "chain",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "identifier",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ESOrderDataType",
      "kind": "LinkedField",
      "name": "orderData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderV2Type",
          "kind": "LinkedField",
          "name": "bestAskV2",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "orderType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AccountType",
              "kind": "LinkedField",
              "name": "maker",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "address",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PriceType",
              "kind": "LinkedField",
              "name": "perUnitPriceType",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "usd",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "unit",
                  "storageKey": null
                },
                (v1/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PaymentAssetType",
              "kind": "LinkedField",
              "name": "payment",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "usdPrice",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SweepContextProvider_items"
    }
  ],
  "type": "ItemType",
  "abstractKey": "__isItemType"
};
})();

(node as any).hash = "a21c7cfb40458b1ec42d29d1aa5c3be3";

export default node;
