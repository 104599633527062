import { useEffect } from "react"
import { useAccountOrCollectionPageContext } from "@/components/layout/AccountOrCollectionPage/hooks/useAccountOrCollectionPageContext"
import { CollectionAssetSearchState } from "@/features/collections/components/CollectionPage/components/CollectionAssetSearch/CollectionAssetSearch.react"

const SHOULD_BE_UNDEFINED = [
  "numericTraits",
  "paymentAssets",
  "priceFilter",
  "rarityFilter",
  "stringTraits",
] as const

const stateIsAcceptableToPoll = (searchState: CollectionAssetSearchState) => {
  for (const key of SHOULD_BE_UNDEFINED) {
    if (searchState[key as keyof typeof searchState] !== undefined) {
      return false
    }
  }

  const isEmptyQuery = !searchState.query
  const assetsOnly = searchState.resultModel === "ASSETS"
  const sortAscendingOnly = searchState.sortAscending
  const sortByPrice = searchState.sortBy === "UNIT_PRICE"
  const buyNowOrTogglesNotSet =
    (searchState.toggles?.length === 1 &&
      searchState.toggles[0] === "BUY_NOW") ||
    searchState.toggles?.length === 0 ||
    searchState.toggles === undefined

  return (
    isEmptyQuery &&
    assetsOnly &&
    sortAscendingOnly &&
    sortByPrice &&
    buyNowOrTogglesNotSet
  )
}

export const useSetCollectionAcceptableForPolling = (
  searchState: CollectionAssetSearchState,
) => {
  const {
    isSearchStateAcceptableForPolling,
    setIsSearchStateAcceptableForPolling,
    isBuyNowToggled,
    setIsBuyNowToggled,
  } = useAccountOrCollectionPageContext()

  useEffect(() => {
    const isAcceptableToPoll = stateIsAcceptableToPoll(searchState)
    const buyNow =
      searchState.toggles !== undefined &&
      searchState.toggles.includes("BUY_NOW")

    if (isAcceptableToPoll !== isSearchStateAcceptableForPolling) {
      setIsSearchStateAcceptableForPolling(isAcceptableToPoll)
    }
    if (isBuyNowToggled !== buyNow) {
      setIsBuyNowToggled(buyNow)
    }
  }, [
    isSearchStateAcceptableForPolling,
    searchState,
    setIsSearchStateAcceptableForPolling,
    isBuyNowToggled,
    setIsBuyNowToggled,
  ])
}
