import { useMemo } from "react"
import { graphql, useSubscription } from "react-relay"
import { GraphQLSubscriptionConfig } from "relay-runtime"
import { SlugProps } from "@/features/live-updates/components/CollectionPageSubscriptions/CollectionPageSubscriptions.react"
import { eventHistory } from "@/features/live-updates/components/eventHistory"
import { updateCollectionStoreWithListingEventData } from "@/features/live-updates/utils"
import { useCollectionPageListingEventSubscription } from "@/lib/graphql/__generated__/useCollectionPageListingEventSubscription.graphql"

export const useCollectionPageListingEvent = ({ slug }: SlugProps) => {
  const config: GraphQLSubscriptionConfig<useCollectionPageListingEventSubscription> =
    useMemo(
      () => ({
        onNext: payload => {
          eventHistory.pushListingEvent(payload)
        },
        variables: { slug },
        subscription: graphql`
          subscription useCollectionPageListingEventSubscription(
            $slug: String!
          ) {
            listingEvent(slug: $slug) {
              __typename
              ... on ListingEvent {
                relayId
                ethPrice
                unitPrice
                usdPrice
                symbol
                sellOrderRelayId
                paymentAssetRelayId
                item {
                  ...readItemBestAskUsdPrice_item
                  ...AssetSearchList_data @arguments(showContextMenu: true)
                  ...useGetEligibleItemsForSweep_items
                }
              }
            }
          }
        `,
        updater: (store, data) => {
          if (data.listingEvent.__typename !== "ListingEvent") {
            return
          }
          updateCollectionStoreWithListingEventData(store, data, slug)
        },
      }),
      [slug],
    )

  useSubscription<useCollectionPageListingEventSubscription>(config)
}
