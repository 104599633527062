/**
 * @generated SignedSource<<c9c64fcc4ea5f6592c87ae13da84058c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type TransactionStatus = "Cancel" | "Confirmed" | "Dropped" | "Failed" | "Pending" | "PendingSimulation" | "Speedup" | "Stuck" | "%future added value";
export type useCollectionPagePendingTxnEventSubscription$variables = {
  contractAddress: string;
};
export type useCollectionPagePendingTxnEventSubscription$data = {
  readonly mempoolEvent: ReadonlyArray<{
    readonly __typename: "MempoolEvent";
    readonly asset: {
      readonly relayId: string;
    } | null;
    readonly blockExplorerLink: string;
    readonly maxFeeGwei: string;
    readonly priorityFeeGwei: string;
    readonly seenAt: string | null;
    readonly status: TransactionStatus;
    readonly transactionHash: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  }>;
};
export type useCollectionPagePendingTxnEventSubscription = {
  response: useCollectionPagePendingTxnEventSubscription$data;
  variables: useCollectionPagePendingTxnEventSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contractAddress"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "contractAddress",
    "variableName": "contractAddress"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "priorityFeeGwei",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "maxFeeGwei",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "transactionHash",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "blockExplorerLink",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "seenAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCollectionPagePendingTxnEventSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "mempoolEvent",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AssetType",
                "kind": "LinkedField",
                "name": "asset",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "type": "MempoolEvent",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCollectionPagePendingTxnEventSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "mempoolEvent",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AssetType",
                "kind": "LinkedField",
                "name": "asset",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "type": "MempoolEvent",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fbe8862e22171b010e6d1d0cf5d4cc1c",
    "id": null,
    "metadata": {},
    "name": "useCollectionPagePendingTxnEventSubscription",
    "operationKind": "subscription",
    "text": "subscription useCollectionPagePendingTxnEventSubscription(\n  $contractAddress: AddressScalar!\n) {\n  mempoolEvent(contractAddress: $contractAddress) {\n    __typename\n    ... on MempoolEvent {\n      asset {\n        relayId\n        id\n      }\n      priorityFeeGwei\n      maxFeeGwei\n      transactionHash\n      blockExplorerLink\n      status\n      seenAt\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d5b90b70d3e13ed6a1b364e93a264e97";

export default node;
