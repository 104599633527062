/**
 * @generated SignedSource<<88accc980b402e43b97ec7067657ea59>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CollectionAssetSearchListPagination_data$data = {
  readonly collectionItems: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"AssetSearchList_data" | "readItemBestAskUsdPrice_item" | "useGetEligibleItemsForSweep_items">;
      };
    } | null>;
    readonly totalCount: number;
  };
  readonly queriedAt: string;
  readonly " $fragmentType": "CollectionAssetSearchListPagination_data";
};
export type CollectionAssetSearchListPagination_data$key = {
  readonly " $data"?: CollectionAssetSearchListPagination_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"CollectionAssetSearchListPagination_data">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "collectionItems"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "collections"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterOutListingsWithoutRequestedCreatorFees"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "numericTraits"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "owner"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "paymentAssets"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "priceFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "query"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "rarityFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "resultModel"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "safelistRequestStatuses"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "shouldShowBestBid"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sortAscending"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sortBy"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "stringTraits"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "toggles"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./CollectionAssetSearchListPaginationQuery.graphql')
    }
  },
  "name": "CollectionAssetSearchListPagination_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "queriedAt",
      "storageKey": null
    },
    {
      "alias": "collectionItems",
      "args": [
        {
          "kind": "Variable",
          "name": "collections",
          "variableName": "collections"
        },
        {
          "kind": "Variable",
          "name": "filterOutListingsWithoutRequestedCreatorFees",
          "variableName": "filterOutListingsWithoutRequestedCreatorFees"
        },
        {
          "kind": "Variable",
          "name": "numericTraits",
          "variableName": "numericTraits"
        },
        {
          "kind": "Variable",
          "name": "owner",
          "variableName": "owner"
        },
        {
          "kind": "Variable",
          "name": "paymentAssets",
          "variableName": "paymentAssets"
        },
        {
          "kind": "Variable",
          "name": "priceFilter",
          "variableName": "priceFilter"
        },
        {
          "kind": "Literal",
          "name": "prioritizeBuyNow",
          "value": true
        },
        {
          "kind": "Variable",
          "name": "querystring",
          "variableName": "query"
        },
        {
          "kind": "Variable",
          "name": "rarityFilter",
          "variableName": "rarityFilter"
        },
        {
          "kind": "Variable",
          "name": "resultType",
          "variableName": "resultModel"
        },
        {
          "kind": "Variable",
          "name": "safelistRequestStatuses",
          "variableName": "safelistRequestStatuses"
        },
        {
          "kind": "Variable",
          "name": "sortAscending",
          "variableName": "sortAscending"
        },
        {
          "kind": "Variable",
          "name": "sortBy",
          "variableName": "sortBy"
        },
        {
          "kind": "Variable",
          "name": "stringTraits",
          "variableName": "stringTraits"
        },
        {
          "kind": "Variable",
          "name": "toggles",
          "variableName": "toggles"
        }
      ],
      "concreteType": "ItemTypeConnection",
      "kind": "LinkedField",
      "name": "__CollectionAssetSearchListPagination_collectionItems_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ItemTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "readItemBestAskUsdPrice_item",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ESOrderDataType",
                      "kind": "LinkedField",
                      "name": "orderData",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "OrderV2Type",
                          "kind": "LinkedField",
                          "name": "bestAskV2",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "PriceType",
                              "kind": "LinkedField",
                              "name": "perUnitPriceType",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "usd",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            (v1/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                },
                {
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "shouldShowBestBid",
                      "variableName": "shouldShowBestBid"
                    }
                  ],
                  "kind": "FragmentSpread",
                  "name": "AssetSearchList_data"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "useGetEligibleItemsForSweep_items"
                },
                (v1/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "853a7328136a93335a7f7f9f1f75da54";

export default node;
