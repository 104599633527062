import React, { useEffect, useMemo } from "react"
import { useLazyLoadQuery, usePaginationFragment } from "react-relay"
import { useUpdateEffect } from "react-use"
import { SsrSuspense } from "@/components/common/SsrSuspense.react"
import { AssetSearchList } from "@/components/search/assets/AssetSearchList.react"
import {
  AssetCardVariant,
  AssetSearchViewNoResults,
} from "@/components/search/assets/AssetSearchView"
import { filterItemsWithInconsistentListings } from "@/components/search/assets/utils/filterItems"
import { useUpdateSearchContext } from "@/components/search/utils/SearchContextProvider.react"
import { COLLECTION_MAX_PAGE_SIZE } from "@/constants/index"
import { loadNextToLoadMore } from "@/design-system/ScrollingPaginator"
import { CollectionAssetSearchState } from "@/features/collections/components/CollectionPage/components/CollectionAssetSearch/CollectionAssetSearch.react"
import { shouldShowBestBid } from "@/features/collections/components/CollectionPage/shouldShowBestBid"
import { useSetEligibleItemsForSweep } from "@/features/sweep/hooks/useGetEligibleItemsForSweep"
import { CollectionAssetSearchListPagination_data$key } from "@/lib/graphql/__generated__/CollectionAssetSearchListPagination_data.graphql"
import { CollectionAssetSearchListQuery } from "@/lib/graphql/__generated__/CollectionAssetSearchListQuery.graphql"
import { getNodes, graphql, PageProps } from "@/lib/graphql/graphql"

type Props = {
  cardVariant: AssetCardVariant | undefined
  clear: () => void
  fullWidth?: boolean
  searchState: CollectionAssetSearchState
}

type PaginationProps = Props & {
  data: CollectionAssetSearchListPagination_data$key | null
}

const LIST_QUERY = graphql`
  query CollectionAssetSearchListQuery(
    $collections: [CollectionSlug!]!
    $count: Int!
    $numericTraits: [TraitRangeType!]
    $paymentAssets: [PaymentAssetSymbol]
    $priceFilter: PriceFilterType
    $query: String
    $rarityFilter: RarityFilterType
    $resultModel: SearchResultModel
    $sortAscending: Boolean
    $sortBy: SearchSortBy
    $stringTraits: [TraitInputType!]
    $toggles: [SearchToggle!]
    $shouldShowBestBid: Boolean!
    $owner: IdentityInputType
    $filterOutListingsWithoutRequestedCreatorFees: Boolean
  ) {
    ...CollectionAssetSearchListPagination_data
      @arguments(
        count: $count
        collections: $collections
        numericTraits: $numericTraits
        paymentAssets: $paymentAssets
        priceFilter: $priceFilter
        query: $query
        rarityFilter: $rarityFilter
        resultModel: $resultModel
        sortAscending: $sortAscending
        sortBy: $sortBy
        stringTraits: $stringTraits
        toggles: $toggles
        shouldShowBestBid: $shouldShowBestBid
        owner: $owner
        filterOutListingsWithoutRequestedCreatorFees: $filterOutListingsWithoutRequestedCreatorFees
      )
  }
`

const PAGINATION_QUERY = graphql`
  fragment CollectionAssetSearchListPagination_data on Query
  @argumentDefinitions(
    collections: { type: "[CollectionSlug!]" }
    count: { type: "Int!" }
    cursor: { type: "String" }
    numericTraits: { type: "[TraitRangeType!]" }
    paymentAssets: { type: "[PaymentAssetSymbol]" }
    priceFilter: { type: "PriceFilterType" }
    query: { type: "String" }
    rarityFilter: { type: "RarityFilterType" }
    resultModel: { type: "SearchResultModel" }
    safelistRequestStatuses: { type: "[SafelistRequestStatus!]" }
    sortAscending: { type: "Boolean" }
    sortBy: { type: "SearchSortBy" }
    stringTraits: { type: "[TraitInputType!]" }
    toggles: { type: "[SearchToggle!]" }
    shouldShowBestBid: { type: "Boolean!" }
    owner: { type: "IdentityInputType" }
    filterOutListingsWithoutRequestedCreatorFees: { type: "Boolean" }
  )
  @refetchable(queryName: "CollectionAssetSearchListPaginationQuery") {
    queriedAt
    collectionItems(
      first: $count
      after: $cursor
      collections: $collections
      numericTraits: $numericTraits
      paymentAssets: $paymentAssets
      priceFilter: $priceFilter
      querystring: $query
      rarityFilter: $rarityFilter
      resultType: $resultModel
      safelistRequestStatuses: $safelistRequestStatuses
      sortAscending: $sortAscending
      sortBy: $sortBy
      stringTraits: $stringTraits
      toggles: $toggles
      owner: $owner
      prioritizeBuyNow: true
      filterOutListingsWithoutRequestedCreatorFees: $filterOutListingsWithoutRequestedCreatorFees
    ) @connection(key: "CollectionAssetSearchListPagination_collectionItems") {
      edges {
        node {
          # eslint-disable-next-line relay/must-colocate-fragment-spreads
          ...readItemBestAskUsdPrice_item
          ...AssetSearchList_data
            @arguments(shouldShowBestBid: $shouldShowBestBid)
          ...useGetEligibleItemsForSweep_items
        }
      }
      totalCount
    }
  }
`

const LazyCollectionAssetSearchList = (props: Props) => {
  const data = useLazyLoadQuery<CollectionAssetSearchListQuery>(LIST_QUERY, {
    ...props.searchState,
    count: COLLECTION_MAX_PAGE_SIZE,
    shouldShowBestBid: shouldShowBestBid({
      assetCardVariant: props.cardVariant,
      searchState: props.searchState,
    }),
  })
  return <CollectionAssetSearchListPagination data={data} {...props} />
}

const CollectionAssetSearchListPagination = ({
  cardVariant,
  data: dataKey,
  fullWidth,
  clear,
  searchState,
}: PaginationProps) => {
  const { data, loadNext, hasNext, isLoadingNext, refetch } =
    usePaginationFragment<
      CollectionAssetSearchListQuery,
      CollectionAssetSearchListPagination_data$key
    >(PAGINATION_QUERY, dataKey)

  const page: PageProps = useMemo(
    () => ({
      loadMore: count => loadNextToLoadMore({ loadNext, count }),
      isLoading: () => isLoadingNext,
      hasMore: () => hasNext,
    }),
    [hasNext, isLoadingNext, loadNext],
  )

  const fetchBestBid = useMemo(
    () =>
      shouldShowBestBid({
        assetCardVariant: cardVariant,
        searchState,
      }),
    [cardVariant, searchState],
  )

  useUpdateEffect(() => {
    refetch(
      {
        ...searchState,
        count: COLLECTION_MAX_PAGE_SIZE,
        shouldShowBestBid: fetchBestBid,
      },
      { fetchPolicy: "store-and-network" },
    )
  }, [searchState, fetchBestBid])

  const updateSearchContext = useUpdateSearchContext()

  const collectionItems = data?.collectionItems
  const totalCount = collectionItems?.totalCount
  const lastUpdatedAt = data?.queriedAt

  useEffect(() => {
    updateSearchContext({
      totalCount,
      lastUpdatedAt,
    })
  }, [lastUpdatedAt, totalCount, updateSearchContext])

  const filteredData = filterItemsWithInconsistentListings(
    getNodes(collectionItems),
    searchState,
    page,
  )

  useSetEligibleItemsForSweep(filteredData)

  const pagination = useMemo(
    () => ({
      hasNext,
      isLoadingNext,
      loadNext: () => loadNext(COLLECTION_MAX_PAGE_SIZE),
      paginationThreshold: 12,
    }),
    [hasNext, isLoadingNext, loadNext],
  )

  if (!data) {
    return <AssetSearchList.Skeleton cardVariant={cardVariant} />
  }

  return collectionItems && totalCount && totalCount > 0 ? (
    <AssetSearchList
      cardVariant={cardVariant}
      data={filteredData}
      fullWidth={fullWidth}
      page={page}
      pageSize={COLLECTION_MAX_PAGE_SIZE}
      pagination={pagination}
      showQuantityBadge
      variant="grid"
    />
  ) : (
    <AssetSearchViewNoResults
      query={searchState.query}
      showEmptyView
      onBackClick={clear}
    />
  )
}
export const CollectionAssetSearchList = ({ cardVariant, ...props }: Props) => (
  <SsrSuspense
    fallback={<AssetSearchList.Skeleton cardVariant={cardVariant} />}
  >
    <LazyCollectionAssetSearchList cardVariant={cardVariant} {...props} />
  </SsrSuspense>
)
