import React, { useCallback, useEffect, useRef, useState } from "react"
import { useUpdateEffect } from "react-use"
import { graphql } from "relay-runtime"
import { useDebouncedCallback } from "use-debounce"
import { BlockchainActionModalContent } from "@/components/blockchain/BlockchainActionList/BlockchainActionModalContent.react"
import { AssetCardVariantToggle } from "@/components/layout/AccountOrCollectionPage/components/AssetCardVariantToggle"
import { FilterBar } from "@/components/layout/AccountOrCollectionPage/components/FilterBar"
import { useAccountOrCollectionPageContext } from "@/components/layout/AccountOrCollectionPage/hooks/useAccountOrCollectionPageContext"
import AssetSearchFilter, {
  getFilterCount,
} from "@/components/search/assets/AssetSearchFilter"
import { AssetSearchLayout } from "@/components/search/assets/AssetSearchLayout/AssetSearchLayout.react"
import { AssetSearchRefreshBar } from "@/components/search/assets/AssetSearchRefreshBar"
import { Sort } from "@/components/search/assets/AssetSearchSortDropdown.react"
import { AssetSearchTraitAndQueryInput } from "@/components/search/assets/AssetSearchTraitAndQueryInput"
import {
  AssetCardVariant,
  mergeTraitFilterState,
  updateCardVariantCookie,
} from "@/components/search/assets/AssetSearchView"
import { PhoenixAssetSearchSortDropdown } from "@/components/search/assets/AssetSearchView/elements"
import { SearchPills } from "@/components/search/SearchPills.react"
import { ToggleFilterButton } from "@/components/search/ToggleFilterButton.react"
import { QuickBuyModalContent } from "@/components/trade/QuickBuyModalContent"
import { SelectedTrait } from "@/components/traits/TraitSelector"
import { useActiveAccount } from "@/containers/WalletProvider/WalletProvider.react"
import { Block } from "@/design-system/Block"
import { MultiStepModal } from "@/design-system/Modal/MultiStepModal.react"
import { CollectionPageSubscriptions } from "@/features/live-updates/components/CollectionPageSubscriptions"
import { eventHistory } from "@/features/live-updates/components/eventHistory"
import { LiveDataIndicator } from "@/features/live-updates/components/LiveDataIndicator"
import { useIsCollectionEligibleForLiveUpdates } from "@/features/live-updates/hooks/useIsLiveUpdatesEnabledForCollection"
import { useNoSuspenseLazyLoadQuery } from "@/hooks/useNoSuspenseLazyLoadQuery"
import { Search } from "@/hooks/useSearch"
import { useSetCollectionAcceptableForPolling } from "@/hooks/useSetCollectionAcceptableForPolling"
import { trackNavTraitClick } from "@/lib/analytics/events/navSearchEvents"
import { trackSearch } from "@/lib/analytics/events/searchEvents"
import { ItemSearchTrackingContextProvider } from "@/lib/analytics/TrackingContext/contexts/ItemSearchTrackingContext.react"
import { SourceTrackingContextProvider } from "@/lib/analytics/TrackingContext/contexts/SourceTrackingContext.react"
import { CollectionAssetSearchQuery } from "@/lib/graphql/__generated__/CollectionAssetSearchQuery.graphql"
import { RequiredKeys } from "@/lib/helpers/type"
import { CollectionAssetSearchList } from "./CollectionAssetSearchList.react"

const CONTENT_REFRESH_DEBOUNCE_WAIT_TIME = 5_000

export type CollectionAssetSearchState = RequiredKeys<
  Pick<
    Search,
    | "numericTraits"
    | "paymentAssets"
    | "priceFilter"
    | "rarityFilter"
    | "resultModel"
    | "stringTraits"
    | "toggles"
    | "query"
    | "owner"
    | "filterOutListingsWithoutRequestedCreatorFees"
  >
> &
  Required<
    Pick<Search, "collection" | "collections" | "sortAscending" | "sortBy">
  >

type Props = {
  searchState: CollectionAssetSearchState
  clear: () => void
  update: (
    updatedState?: Partial<CollectionAssetSearchState> | undefined,
  ) => Promise<void>
  assetCardVariant: AssetCardVariant | undefined
}

const ASSET_SEARCH_COLLECTION_SORTS: Sort[] = [
  { sortAscending: true, sortBy: "UNIT_PRICE" },
  { sortAscending: false, sortBy: "UNIT_PRICE" },
  { sortAscending: false, sortBy: "LISTING_DATE" },
  { sortAscending: false, sortBy: "BEST_BID" },
  { sortAscending: true, sortBy: "RARITY_RANK" },
  { sortAscending: false, sortBy: "LAST_SALE_PRICE" },
  { sortAscending: false, sortBy: "LAST_SALE_DATE" },
  { sortAscending: false, sortBy: "CREATED_DATE" },
  { sortAscending: false, sortBy: "VIEWER_COUNT" },
  { sortAscending: true, sortBy: "CREATED_DATE" },
  { sortAscending: false, sortBy: "FAVORITE_COUNT" },
  { sortAscending: true, sortBy: "EXPIRATION_DATE" },
  { sortAscending: false, sortBy: "LAST_TRANSFER_DATE" },
]

export const CollectionAssetSearch = ({
  searchState,
  update,
  clear,
  assetCardVariant: initialAssetCardVariant,
}: Props) => {
  const { quickBuyModalOrderRelayId, setQuickBuyModalOrderRelayId } =
    useAccountOrCollectionPageContext()
  const activeAccount = useActiveAccount()
  const scrollRef = useRef<HTMLDivElement>(null)

  const filterCount = getFilterCount(searchState, {
    includeCollectionFilter: false,
  })
  const [assetCardVariant, setAssetCardVariant] = useState(
    initialAssetCardVariant,
  )

  useUpdateEffect(
    () => updateCardVariantCookie("global", assetCardVariant),
    [assetCardVariant],
  )

  const [data] = useNoSuspenseLazyLoadQuery<CollectionAssetSearchQuery>(
    graphql`
      query CollectionAssetSearchQuery($collection: CollectionSlug!) {
        collection(collection: $collection) {
          representativeAsset {
            assetContract {
              address
            }
          }
          isCreatorFeesEnforced
          totalCreatorFeeBasisPoints

          ...useIsLiveUpdatesEnabledForCollection_collection
          ...AssetSearchRefreshBar_collection
          ...AssetSearchSortDropdown_collection
        }
        ...AssetSearchFilter_data @arguments(collection: $collection)
      }
    `,
    { collection: searchState.collection },
  )
  useEffect(() => {
    trackSearch({
      type: "AssetCollectionSearch",
      path: window.location.pathname,
      queryString: window.location.search,
      pageIndex: 0,
      itemDisplayVariant: assetCardVariant ?? "unset",
      ...searchState,
    })
  }, [searchState, assetCardVariant])

  const setStringTrait = useCallback(
    (name: string, values?: ReadonlyArray<string>) => {
      const traits = [
        ...(searchState.stringTraits?.filter(t => t.name !== name) ?? []),
        ...(values ? [{ name, values }] : []),
      ]
      return update({
        stringTraits: traits.length ? traits : undefined,
      })
    },
    [searchState.stringTraits, update],
  )

  const onSelectTrait = (trait: SelectedTrait) => {
    if (!trait) {
      return
    }
    mergeTraitFilterState(searchState, update, trait.key, trait.value)
    trackNavTraitClick({
      collection: searchState.collection,
      traitName: trait.key,
      traitValue: trait.value,
      resultArea: "search-page",
      resultType: "trait",
      query: searchState.query,
      path: window.location.pathname,
    })
  }

  const showLiveUpdates = useIsCollectionEligibleForLiveUpdates(
    data?.collection ?? null,
  )

  useSetCollectionAcceptableForPolling(searchState)

  const contractAddress =
    data?.collection?.representativeAsset?.assetContract.address
  eventHistory.setSlug(searchState.collection)

  const onSubscribeCallback = useDebouncedCallback(
    () => update({ ...searchState }),
    // We want to prevent too many reconnections from causing hard refreshes on the client, but react as quickly as possible when it does happen.
    CONTENT_REFRESH_DEBOUNCE_WAIT_TIME,
    { leading: true, trailing: false },
  )

  const onSubscribe = React.useCallback(
    () => onSubscribeCallback(),
    [onSubscribeCallback],
  )

  return (
    <SourceTrackingContextProvider source="CollectionAssetSearch">
      <ItemSearchTrackingContextProvider searchState={searchState}>
        <MultiStepModal
          isOpen={quickBuyModalOrderRelayId !== undefined}
          onClose={() => setQuickBuyModalOrderRelayId(undefined)}
        >
          {onClose => (
            <>
              {quickBuyModalOrderRelayId ? (
                <QuickBuyModalContent
                  orderId={quickBuyModalOrderRelayId}
                  onClose={onClose}
                />
              ) : (
                // NOTE(@laurafiuza): Won't reach here
                // because modal won't be open unless relayId is defined
                <BlockchainActionModalContent.Skeleton />
              )}
            </>
          )}
        </MultiStepModal>
        {showLiveUpdates && (
          <CollectionPageSubscriptions
            contractAddress={contractAddress}
            slug={searchState.collection}
            onSubscribe={onSubscribe}
          />
        )}
        <Block ref={scrollRef} />
        <AssetSearchLayout
          assets={
            <CollectionAssetSearchList
              cardVariant={assetCardVariant}
              clear={clear}
              searchState={searchState}
            />
          }
          counts={
            !showLiveUpdates && (
              <AssetSearchRefreshBar
                collection={data?.collection ?? null}
                onRefresh={() => update(searchState)}
              />
            )
          }
          filter={
            <AssetSearchFilter
              clear={clear}
              dataKey={data}
              includeCollectionFilter={false}
              includeListingsSupportCreatorFilter={
                // Only show the filter if the collection does not have creator fees enforced
                // and the collection has creator fees set
                Boolean(
                  !data?.collection?.isCreatorFeesEnforced &&
                    data?.collection?.totalCreatorFeeBasisPoints,
                )
              }
              setListingSupportsCreator={listingSupportsCreator =>
                update({
                  filterOutListingsWithoutRequestedCreatorFees:
                    listingSupportsCreator,
                })
              }
              setNumericTrait={(name, range) =>
                update({
                  numericTraits: [
                    ...(searchState.numericTraits?.filter(
                      t => t.name !== name,
                    ) || []),
                    ...(range ? [{ name, ranges: [range] }] : []),
                  ],
                })
              }
              setOwner={
                activeAccount !== undefined
                  ? owner => update({ owner })
                  : undefined
              }
              setPaymentAssets={(paymentAssets?: ReadonlyArray<string>) =>
                update({ paymentAssets })
              }
              setPriceFilter={priceFilter =>
                update({ priceFilter: priceFilter || undefined })
              }
              setRarityFilter={rarityFilter =>
                update({ rarityFilter: rarityFilter ?? undefined })
              }
              setStringTrait={setStringTrait}
              setToggles={toggles => update({ toggles })}
              state={searchState}
            />
          }
          header={
            <FilterBar
              assetCardVariantToggle={
                <AssetCardVariantToggle
                  currentCardVariant={assetCardVariant}
                  setCurrentCardVariant={setAssetCardVariant}
                />
              }
              filterToggle={
                <ToggleFilterButton numFiltersApplied={filterCount} />
              }
              liveDataIndicator={showLiveUpdates && <LiveDataIndicator />}
              search={
                <AssetSearchTraitAndQueryInput
                  collection={searchState.collection}
                  onEnter={query => update({ query: `${query}` })}
                  onSelectTrait={onSelectTrait}
                />
              }
              sortDropdown={
                <PhoenixAssetSearchSortDropdown
                  collection={data?.collection ?? null}
                  fullWidth
                  searchState={searchState}
                  setSort={update}
                  sortOptions={ASSET_SEARCH_COLLECTION_SORTS}
                />
              }
            />
          }
          pills={
            <SearchPills
              clear={clear}
              dataKey={null}
              featuredFilter={searchState.toggles}
              filterOutListingsWithoutRequestedCreatorFees={
                searchState.filterOutListingsWithoutRequestedCreatorFees
              }
              numericTraitFilter={searchState.numericTraits}
              paymentFilter={searchState.paymentAssets}
              priceFilter={searchState.priceFilter}
              queryFilter={searchState.query}
              rarityFilter={searchState.rarityFilter}
              stringTraitFilter={searchState.stringTraits}
              update={update}
            />
          }
        />
      </ItemSearchTrackingContextProvider>
    </SourceTrackingContextProvider>
  )
}
