import { _FragmentRefs } from "relay-runtime"
import { Search } from "@/hooks/useSearch"
import {
  SearchSortBy,
  SearchToggle,
} from "@/lib/graphql/__generated__/AccountCollectedAssetSearchListQuery.graphql"
import { PageProps } from "@/lib/graphql/graphql"
import { readItemBestAskUsdPrice } from "../readItemBestAskUsdPrice"

const ONLY_SHOW_LISTED_ITEMS_TOGGLES: SearchToggle[] = [
  "BUY_NOW",
  "IS_AVAILABLE_FOR_MOONPAY_FIAT_CHECKOUT",
]
const LISTING_PRICE_SORTS: SearchSortBy[] = ["UNIT_PRICE", "PRICE"]

type SearchNode = _FragmentRefs<
  "readItemBestAskUsdPrice_item" | "AssetSearchList_data"
>

/**
 * If a buy now toggle or price sort is applied then filter out items without a best ask.
 * This can happen due to inconsistencies between ES data and hydrated data from PG.
 * TODO: Make this into a hook instead
 */
export const filterItemsWithInconsistentListings = <T extends SearchNode>(
  items: T[],
  searchState: Search,
  page: PageProps,
): T[] | null => {
  const sortValidItems = (validItems: T[]) => {
    if (!searchState.sortAscending) {
      return
    }

    validItems.sort((a, b) => {
      const aPrice = readItemBestAskUsdPrice(a)
      const bPrice = readItemBestAskUsdPrice(b)

      if (aPrice && bPrice) {
        return aPrice.isGreaterThan(bPrice) ? 1 : -1
      }

      return 0
    })
  }

  const onlyShowListedItems = searchState.toggles?.some(toggle =>
    ONLY_SHOW_LISTED_ITEMS_TOGGLES.includes(toggle),
  )
  if (onlyShowListedItems) {
    const validItems = items.filter(item => readItemBestAskUsdPrice(item))
    sortValidItems(validItems)
    return validItems
  }

  const isSortedByListingPrice =
    searchState.sortBy && LISTING_PRICE_SORTS.includes(searchState.sortBy)
  if (isSortedByListingPrice) {
    const lastListedIndex = items.reduce((lastListedIndex, item, index) => {
      return readItemBestAskUsdPrice(item) ? index : lastListedIndex
    }, 0)

    // Group into valid and invalid items, preserving sort order
    const { validItems, invalidItems } = items.reduce<{
      validItems: T[]
      invalidItems: T[]
    }>(
      ({ validItems, invalidItems }, item, index) => {
        if (index > lastListedIndex) {
          // After lastListedIndex we must treat unlisted items as valid
          // since we don't know if there are listed items on the next page or not
          return {
            validItems: [...validItems, item],
            invalidItems,
          }
        }

        // Before lastListedIndex the item should have a price (bestAsk)
        // to be consistent with the sort ordering
        return readItemBestAskUsdPrice(item)
          ? {
              validItems: [...validItems, item],
              invalidItems,
            }
          : {
              validItems,
              invalidItems: [...invalidItems, item],
            }
      },
      {
        validItems: [],
        invalidItems: [],
      },
    )

    sortValidItems(validItems)

    // If there are no more pages to be loaded, show the unlisted items
    // They should appear at the end of the list due to listing price sort
    return page.hasMore() ? validItems : [...validItems, ...invalidItems]
  }

  return items
}
