/**
 * @generated SignedSource<<91b8b6215d14a37850af4e9e91520173>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type readItemBestAskUsdPrice_item$data = {
  readonly orderData: {
    readonly bestAskV2: {
      readonly __typename: "OrderV2Type";
      readonly perUnitPriceType: {
        readonly usd: string;
      };
    } | null;
  };
  readonly " $fragmentType": "readItemBestAskUsdPrice_item";
};
export type readItemBestAskUsdPrice_item$key = {
  readonly " $data"?: readItemBestAskUsdPrice_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"readItemBestAskUsdPrice_item">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "readItemBestAskUsdPrice_item"
};

(node as any).hash = "daee3026962ea32979f503e1c95ff7e2";

export default node;
