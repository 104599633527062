import React from "react"
import { IS_STORYBOOK } from "@/constants/environment"
import { useCollectionPageListingEvent } from "../../hooks/useCollectionPageListingEvent"
import { useCollectionPageListingUpdatedEvent } from "../../hooks/useCollectionPageListingUpdatedEvent"
import { useCollectionPagePendingTxnEvent } from "../../hooks/useCollectionPagePendingTxnEvent"
import { useCollectionPageSaleEvent } from "../../hooks/useCollectionPageSaleEvent"
import { useIsLiveUpdatesEnabledForCollection } from "../../hooks/useIsLiveUpdatesEnabledForCollection"

/**
 * These components allow us to call subscription hooks conditionally with Unleash.
 * Otherwise these subscriptions would be used directly in the parent component
 */

export type SlugProps = {
  slug: string
}

type SubscriptionProps = {
  contractAddress: string | undefined
  onSubscribe: () => void
  slug: string
}

const CollectionPageListingCancellationSubscription = ({ slug }: SlugProps) => {
  useCollectionPageListingUpdatedEvent({ slug })
  return <></>
}

const CollectionPageListingSubscription = ({ slug }: SlugProps) => {
  useCollectionPageListingEvent({ slug })
  return <></>
}

export type ContractAddressProps = {
  contractAddress: string
}

export const CollectionPagePendingTxnSubscription = ({
  contractAddress,
}: ContractAddressProps) => {
  useCollectionPagePendingTxnEvent({ contractAddress })
  return <></>
}

export const CollectionPageSubscriptions = ({
  contractAddress,
  onSubscribe,
  slug,
}: SubscriptionProps) => {
  const isLiveUpdatesEnabledForCollection =
    useIsLiveUpdatesEnabledForCollection(null)

  useCollectionPageSaleEvent({ slug, onSubscribe })
  if (IS_STORYBOOK) {
    return <></>
  }

  return (
    <>
      <CollectionPageListingCancellationSubscription slug={slug} />
      {isLiveUpdatesEnabledForCollection ? (
        <CollectionPageListingSubscription slug={slug} />
      ) : null}
      {contractAddress ? (
        <CollectionPagePendingTxnSubscription
          contractAddress={contractAddress}
        />
      ) : null}
    </>
  )
}
